import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import ContraceptionInfo from "../../components/services/ContraceptionInfo";

const Contraception = () => {
  return (
    <Layout>
      <PageHeader text="Contraception" />
      <ServicesHeader>
        If you’re interested in contraception because you’re sexually active but
        not ready to become pregnant, internal medicine experts at Sunstate
        Medical Associates in Lake Mary, Florida, offer a wide array of birth
        control solutions. Schedule an appointment by phone and speak with a
        friendly team member or online to learn more about your options.
      </ServicesHeader>
      <ContraceptionInfo />
      <Conditions />
    </Layout>
  );
};

export default Contraception;
