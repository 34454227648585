import React from "react";
import RequestButton from "../RequestButton";

const ContraceptionInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Contraception Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">What is contraception?</p>
        <p className="text-md text-gray-600 mb-12">
          Contraception is a way to prevent becoming pregnant when you’re
          sexually active. The team at Sunstate Medical Associates offers birth
          control pills to put you in control of your own body.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Which form of birth control is best for me?
        </p>
        <p className="text-md text-gray-600 mb-12">
          To find birth control pills that match your needs, your Sunstate
          Medical Associates specialist reviews your medical history, completes
          a physical exam, and asks about your lifestyle and preferences. They
          might screen you for certain diseases if you’re at risk.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Which types of birth control are available?
        </p>
        <p className="text-md text-gray-600 mb-3">
          If you and your doctor determine you’re a good candidate for birth
          control pills, they discuss the different types to find one you’re
          comfortable with. Examples include:
        </p>
        <p className="text-lg text-gray-700 mb-2">
          Combination birth control pills
        </p>
        <p className="text-md text-gray-600 mb-3">
          Combination birth control pills contain both estrogen and progestin.
          Both of these hormones work together to prevent pregnancy.
        </p>
        <p className="text-lg text-gray-700 mb-2">Mini pills</p>
        <p className="text-md text-gray-600 mb-3">
          Mini birth control pills contain progestin only. Each dose of
          progestin in mini pills is lower than the amount in combination pills.
        </p>
        <p className="text-lg text-gray-700 mb-2">Extended-cycle pills</p>
        <p className="text-md text-gray-600 mb-3">
          To reduce the number of periods you have while taking birth control
          pills, your doctor might recommend extended-cycle pills to make you
          have just four periods yearly.
        </p>
        <p className="text-lg text-gray-700 mb-2">Low-dose pills</p>
        <p className="text-md text-gray-600 mb-12">
          If you’re sensitive to hormones, low-dose birth control pills
          containing less estrogen per active pill might be a good option for
          you.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How do birth control pills work?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Combination pills prevent your ovaries from releasing eggs or slowing
          an egg’s progression through the fallopian tube. Combination pills can
          make cervical mucus thicker or thin your uterine lining. All of these
          actions can prevent sperm from reaching your egg to prevent pregnancy.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Mini birth control pills can slow an egg’s progression through the
          fallopian tube, thin your endometrium, thicken cervical mucus, and
          sometimes suppress ovulation to prevent sperm from fertilizing your
          egg.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the benefits of birth control pills?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Birth control pills are a noninvasive form of contraception that’s
          highly effective for pregnancy prevention. It’s important to take the
          pills as instructed to ensure maximum effectiveness. Remember to use
          another form of protection, such as condoms, to prevent sexually
          transmitted diseases (STDs).
        </p>
        <p className="text-md text-gray-600 mb-10">
          If you’re sexually active and want to avoid pregnancy, schedule a
          contraception consultation with Sunstate Medical Associates over the
          phone or online today.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default ContraceptionInfo;
